<template>
  <img
    v-if="coin && !errorLoading"
    loading="lazy"
    alt="..."
    :src="iconURL"
    :class="[styleClass, 'inline rounded-full']"
    @error="errorLoading = true"
  />
  <div v-else-if="!coin || errorLoading" class="inline rounded-full bg-gray-600" :class="styleClass"></div>
</template>
<script setup>
import { computed, ref } from 'vue';
const props = defineProps({
  coin: { type: String },
  styleClass: { type: String, default: 'mr-2' }
});
const iconURL = computed(() => {
  return `https://terminal.cloudfront.thetie.io/coin_images/${props.coin}.png`;
});
const errorLoading = ref(false);
</script>
